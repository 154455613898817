
@import '../partials/spacings';

@media (max-width: 640px) {
  ul.sharing-links {
    padding-bottom: 1em;

    li {
      display: block;
      float: left;
      margin-right: $spacing-4 * 0.5;
    }

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}
