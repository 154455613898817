
.card.only-title {
  height: auto;
}

.card,
.labelled-card {
  height: auto;
  min-height: 200px;
  flex-grow: 1;
}

.card {
  margin-bottom: $spacing-3;
  width: 100%;
  background: $white;

  .card-link {
    color: $grey;
    text-decoration: none;
    display: block;

    &:hover .card-inner {
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $light-blue;
      }
    }


    &:focus {
      outline: 3px solid $focus-yellow;
      outline-offset: -3px;
    }
    .card-inner {
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-decoration: none;
        margin:0
      }

      h4{
        line-height: 1.3;
      }
    }

      p.subheading{
        font-size: 16px;
      }
  }

  .card-icon {
    height: 55px;
    margin-bottom: $spacing-4;
  }

  .card-image {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .card-inner {
    padding: 30px 30px 0;

    h3,
    h4,
    h5,
    h6 {
      @include brand-large;
      margin: 0 0 30px;
    }

    p.description {
      margin: 0;
    }

    .card-meta {
      margin-bottom: 0.5em;

      .subheading {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  &.no-padding-card {
    .card-inner {
      padding: 0;

      h3 {
        margin-bottom: 10px;
      }
    }

    .card-image + .card-inner {
      padding-top: 20px;
    }
  }

  &.transparent-card {
    background-color: transparent;
  }

  .description {
    padding: 0 30px;
  }

  &__link {
    text-decoration: none;

    h3 {
      color: $active-blue;
      text-decoration: underline $active-blue;
    }
    p {
      color: black;
    }
    .subheading {
      color: $mid-grey;
      font-size: 16px;
    }
    &--focused {
      &:focus {
        display: block;
        outline: 3px solid $focus-yellow;
      }
    }
  }
}

.card-large-blue-heading h4 {
  color: $color-gov-link;
  font-size: $font-size-h3 !important;
  margin: 0 0 $standard-padding !important;
}

@media (max-width: 640px) {
  .card {
    min-height: auto;
    height: auto;

    .card-icon {
      height: 35px;
    }

    .card-link {
      position: static;
    }
  }
}

.fact-sheet {
  position: relative;

  hr {
    background: $stone;
  }

  .fact-sheet-content {
    padding-top: 70px;
    margin-top: -25px;
  }
}

.highlight {
  margin: 0;
  padding: 15px;
  margin-bottom: -25px;
  margin-left: 25px;
  position: relative;
  z-index: 1;
  display: inline-block;
}
