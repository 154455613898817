
.image-with-caption {
  position: relative;

  img {
    width: 100%;
  }

  .caption {
    position: relative;
    z-index: 1;
    float: right;
    margin-right: 15px;
    margin-top: -40px;
    width: 55%;
    background-color: $white;
    padding: 10px 15px;
    font-size: 16px;
    font-family: $brand-font;

    p {
      margin: 0;
    }

    .sub-caption {
      color: $flag-red;
    }
  }
}
