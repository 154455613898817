// For 'is there anything wrong with this page?' links

@import '../partials/colours';
@import '../partials/spacings';
@import '../../../../../styles/colors/colors.scss';

section.error-reporting {
  padding: $spacing-3 0;
  background-color: $color-main-background;

  .container {
    max-width: 1230px;
  }
}

.error-reporting {
  a {
    font-size: 16px;
    text-align: left;
    color: $grey;
  }
}
