
// UTILITY CLASSES
// ================

@import '../../partials/colours';

// Backgrounds

$colours: (
  ("black", $black),
  ("grey", $grey),
  ("mid-grey", $mid-grey),
  ("light-grey", $light-grey),
  ("offwhite", $offwhite),
  ("white", $white),
  ("purple", $purple),
  ("great-blue", $great-blue),
  ("dark-navy", $dark-navy),
  ("mid-blue", $mid-blue),
  ("active-blue", $active-blue),
  ("light-blue", $light-blue),
  ("aqua", $aqua),
  ("teal", $teal),
  ("flag-red", $flag-red),
  ("great-red", $great-red),
  ("focus-yellow", $focus-yellow),
  ("stone-10", $stone-10),
  ("stone-20", $stone-20),
  ("stone-30", $stone-30),
  ("stone-40", $stone-40),
  ("stone-50", $stone-50),
  ("stone-60", $stone-60),
  ("stone-70", $stone-70),
  ("stone-80", $stone-80),
  ("stone-90", $stone-90),
  ("stone", $stone)
);

@each $colour, $name in $colours {
  .background-#{$colour} {
    background-color: $name;
  }
}

// Text colour

@each $colour, $name in $colours {
  .#{$colour}-text {
    color: $name;
  }
}

// Borders
@each $colour, $name in $colours {
  .border-#{$colour} {
    border-color: $name;
  }
}

.border-thick  {
  border-style: solid;
  border-width: 6px;
}

.border-medium {
  border-style: solid;
  border-width: 4px;
}

.border-thin {
  border-style: solid;
  border-width: 2px;
}

// Section underline

@each $colour, $name in $colours {
  // needs margin when used on section tag
  section.underline-#{$colour} {
    @include container;

    &:after {
      width: auto;
      height: 4px;
      background-color: $name;
    }
  }

  // does not need margin otherwise
  .underline-#{$colour} {
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 4px;
      background-color: $name;
    }
  }
}

// Padding/margin

$spacings: (
  (-30, negative-30),
  (-15, negative-15),
  (0, 0),
  (15, 15),
  (30, 30),
  (45, 45),
  (60, 60),
  (90, 90)
);
$positions: top right bottom left;
$sizes: (641, m), (769, l), (961, xl);
$rules: padding, margin;

@each $rule in $rules {

  // mobile
  @each $position in $positions {
    @each $spacing, $spacing-name in $spacings {
      .#{$rule}-#{$position}-#{$spacing-name} {
        #{$rule}-#{$position}: #{$spacing}px;
      }
    }
  }

  @each $spacing, $spacing-name in $spacings {
    .#{$rule}-#{$spacing-name} {
      #{$rule}: #{$spacing}px;
    }
  }

  @each $spacing, $spacing-name in $spacings {
    .#{$rule}-vertical-#{$spacing-name} {
      #{$rule}-top: #{$spacing}px;
      #{$rule}-bottom: #{$spacing}px;
    }
  }

  @each $spacing, $spacing-name in $spacings {
    .#{$rule}-horizontal-#{$spacing-name} {
      #{$rule}-right: #{$spacing}px;
      #{$rule}-left: #{$spacing}px;
    }
  }

  // tablet, desktop
  @each $size, $size-name in $sizes {
    @media(min-width: #{$size}px) {

      @each $position in $positions {
        @each $spacing, $spacing-name in $spacings {
          .#{$rule}-#{$position}-#{$spacing-name}-#{$size-name} {
            #{$rule}-#{$position}: #{$spacing}px;
          }
        }
      }

      @each $spacing, $spacing-name in $spacings {
        .#{$rule}-#{$spacing-name}-#{$size-name} {
          #{$rule}: #{$spacing}px;
        }
      }

      @each $spacing, $spacing-name in $spacings {
        .#{$rule}-vertical-#{$spacing-name}-#{$size-name} {
          #{$rule}-top: #{$spacing}px;
          #{$rule}-bottom: #{$spacing}px;
        }
      }

      @each $spacing, $spacing-name in $spacings {
        .#{$rule}-horizontal-#{$spacing-name}-#{$size-name} {
          #{$rule}-right: #{$spacing}px;
          #{$rule}-left: #{$spacing}px;
        }
      }

    }
  }
}

// Text

.uppercase { text-transform: uppercase }

// Layout

.with-flag {
  @include container;
  position: relative;

  &:after {
    bottom: -20px;
    background-image: url('/static/images/uj-motif.png');
    background-size: 155px;
    background-repeat: no-repeat;
    height: 30px;
    z-index: 2;
  }
}

section.blue-underline > .container {
  border-bottom: 6px solid $active-blue;
}

.wrap-tight > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.wrap-tight > *:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.centered {
  margin: 0 auto;
  float: none;
}

.relative {
  position: relative;
}

.overflow-auto {
  overflow: auto;
}

.only-display-mobile {
  display: block;
}

@media (min-width: 641px) {
  .only-display-mobile {
    display: none;
  }
}

.only-display-tablet {
  display: block;
}

@media (min-width: 769px) {
  .only-display-tablet {
    display: none;
  }
}

.only-display-desktop {
  display: none;
}

@media (min-width: 769px) {
  .only-display-desktop {
    display: block;
  }
}
