@import '../partials/colours';

#skip-link {
  font-size: 14px;
  line-height: 1.6;
  background-color: transparent;
  color: $white;
  display: inline-block;
  padding: 15px;
  overflow: hidden;
  position: absolute;
  text-indent: -1000px;
  z-index: -1;

  &:focus {
    outline: $focus-yellow solid 4px;
    outline-offset: 0;
    color: $dark-blue;
    left: 0;
    text-indent: 0;
    top: 0;
    z-index: 10;
  }

  @media (min-width: 641px) {
    font-size: 16px;
  }
}
