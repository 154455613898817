
// For listing article links

.content-list {
  .heading {
    margin-bottom: 1.5em;
  }

  li .heading-small {
    margin-top: 0;
  }

  li {
    margin-bottom: 1em;
  }
}
